import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/crq/QuizResult';
import image from '../../assets/images/report/crq.png';

export class CRQ implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, redirectUrl }: any) {

    return {
      subtitle: 'Your CRQ score is:',
      title: `${score.total.score}/100`,
      description: 'A low score suggests a high level of relational inflexibility and overall dissatisfaction. This may reflect frequent negative behaviors and perceptions from and for each other. You and your partner may struggle with insecure attachment patterns, possibly leading to significant challenges in maintaining healthy relationships.',
      bulletPoints: [
        'Complete breakdown of your score.',
        'More information about steps you can take.',
      ],
      color: '#7cd9dd',
      backgroundColor: '#2da1a4',
      redirectUrl,
      image,
    };
  }
}
